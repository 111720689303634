const config = {
  production: {
    url: "https://api.leadsynq.com/",
    apiUrl: "https://api.leadsynq.com/api/",
    AWS_URL: "https://leadsynq-staging-01.s3.ap-south-1.amazonaws.com/",
  },
  staging: {
    url: "https://develop.leadsynq.com/",
    apiUrl: "https://develop-api.leadsynq.com/api/",
    AWS_URL: "https://leadsynq-staging-01.s3.ap-south-1.amazonaws.com/",
    ACCESS_KEY_ID: process.env.REACT_APP_ACCESS_KEY,
    SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,
    AWS_REGION: "ap-south-1",
    bucketName: "leadsynq-staging-01",
  },
  development: {
    url: "http://localhost:4000/",
    apiUrl: "http://localhost:8000/api/",
    // apiUrl: "http://192.168.0.114:4000/api/",
    AWS_URL: "https://leadsynq-staging-01.s3.ap-south-1.amazonaws.com/",
    AWS_REGION: "ap-south-1",
    bucketName: "leadsynq-staging-01",
    ACCESS_KEY_ID: process.env.REACT_APP_ACCESS_KEY,
    SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,
  },
};

// Define the environment numb
export const env = 1; // 0 for development, 1 for staging, 2 for production

// Map the environment number to the corresponding environment name
const envMapping = ["development", "staging", "production"];

// Get the current environment name based on the env number
const currentEnv = envMapping[env];

// Create host configuration based on the selected environment
const hostConfig = {
  WEB_URL: process.env.url || config[currentEnv].url,
  API_URL: config[currentEnv].apiUrl,
  S3_Bucket: config[currentEnv].bucketName || "",
  FileID: config[currentEnv].AWS_URL || "",
  ACCESS_KEY_ID: process.env.REACT_APP_ACCESS_KEY,
  SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,
  IdentityPoolId: "ap-south-1:12306262-648b-4b1e-94a0-b0e6cc576ea6",
  // ACCESS_KEY_ID: "AKIA4ULAQABKWIAY7ZZO",
  // SECRET_ACCESS_KEY: "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",
  AWS_REGION: "ap-south-1",
  AWS_URL: config[currentEnv].AWS_URL || "",
};
// Export hostConfig and intervals together
export { hostConfig };
